function smoothScroll() {
  $('a[href^="#"]').click(function() {
    const speed = 600;
    const href = $(this).attr('href');
    const target = $(href == '#' || href == '' ? 'html' : href);
    const position = target.offset().top;
    $('body, html').delay(200).animate({scrollTop: position}, speed, 'swing');
    return false;
  });
}

function accordion() {
  $('.js-accordionBtn').click(function() {
    const thisBtnData = $(this).attr('data-accordion');
    $('#' + thisBtnData).slideToggle();
    $('.js-active').toggleClass(thisBtnData);
  });
}

function btnClickFunc() {
  const btn = $('.js-btn');

  btn.on('click', function() {
    const btnTargetData = $(this).attr('data-btn');
    const btnAnimationData = $(this).attr('data-animation');
    const target = $('[data-target = "' + btnTargetData + '"]');

    $(this).toggleClass('js-active');

    if (btnAnimationData == 'slide') {
      target.slideToggle(300);
    } else if (btnAnimationData == 'fade') {
      target.fadeToggle(300);
    } else if (btnAnimationData == 'pageTop') {
      $('body, html').animate({
        scrollTop: 0,
      }, 700);
      return false;
    }
  });
}


function headerNav() {
  if ($('.is_home').length) {
    const $win = $(window);
    const $header = $('.siteHeader');
    const animationClass = 'is-animation';

    $win.on('load scroll', function() {
      const value = $(this).scrollTop();
      if (value > 60) {
        $header.addClass(animationClass);
      } else {
        $header.removeClass(animationClass);
      }
    });
  }
}

function headerNavSp() {
  if ($('.is_home').length) {
    const $win = $(window);
    const $header = $('.siteHeaderSP');
    const animationClass = 'is-animationSp';

    $win.on('load scroll', function() {
      const value = $(this).scrollTop();
      if (value > 20) {
        $header.addClass(animationClass);
      } else {
        $header.removeClass(animationClass);
      }
    });
  }
}

const swiperSlider = function swiperSlider() {
  if ($('.swiper-container').length) {
    var topPageSlider = new Swiper('.swiper-container', {
      autoplay: {
        delay: 4000, // 自動スライド・1スライド滞在時間
        disableOnInteraction: false // ユーザーがスライド操作した後もautoplay続行
      },
      speed: 800, // スライド切替スピード
      slidesPerView: 1, // 1スライド表示
      initialSlide: 0, //は最初に何枚目のスライドを表示させるか
      loop: true, // ループ(クローンスライドが作られる)
      infinite: true,
      spaceBetween: 0,
      pagination: {
        el: '.swiper-pagination',
        clickable: true
      },
      breakpoints: {
        767: {
          slidesPerView: 1
        }
      }
    });
  }
};

function headerModal() {
  const nav = $('.siteHeaderSP__nav');
  $('.js-btn').click(function(){
    nav.fadeIn();
  });

  $('.js-closeBtn').click(function(){
    nav.fadeOut();
  });
}

function WOWanimate() {
  new WOW().init();
}

function faqPageTabFunc() {
  //.faqChildrenPageSec__wrap__innerArea__list__item__titleの中のp要素がクリックされたら
  $('.faqChildrenPageSec__wrap__innerArea__list__item__title').click(function(){

    //クリックされた.faqChildrenPageSec__wrap__innerArea__list__item__contentの中のp要素に隣接するul要素が開いたり閉じたりする。
    $(this).next('.faqChildrenPageSec__wrap__innerArea__list__item__content').slideToggle();
    $(this).find('.faqChildrenPageSec__wrap__innerArea__list__item__title__line').toggleClass('open');
  });
}

// function stickyfill() {
//   var elem = document.querySelectorAll('.sticky');
//     Stickyfill.add(elem);
// }


jQuery(function($){
  $('.tab').click(function(){
    $('.is-active').removeClass('is-active');
    $(this).addClass('is-active');
    $('.is-show').removeClass('is-show');
    // クリックしたタブからインデックス番号を取得
    const index = $(this).index();
    // クリックしたタブと同じインデックス番号をもつコンテンツを表示
    $('.panel').eq(index).addClass('is-show');
  });
});

function tabSec() {
  var TabList = function(elParent,contentsParent) {
    this.elParent = $(elParent);
    this.el = this.elParent.find('.one_tab');
    this.contentsParent = $(contentsParent);
    this.contents = this.contentsParent.find('.tab_main');
  };
  TabList.prototype.fn = function() {
    var that = this;
    this.el.on('click', function() {
      var index = $(this).index();
      that.elParent.each(function(){
        $('.one_tab',this).removeClass('select').eq(index).addClass('select');
      });
      that.contents.removeClass('is_show').eq(index).addClass('is_show');
      if($(this).parent().hasClass('bottom')){
        scrollToHash();
      }
    });
    function scrollToHash(){
      var speed = 400;
      var target = that.contentsParent;
      var position = target.offset().top - 580;
      $('body,html').animate({scrollTop:position}, speed, 'swing');
      return false;
    }
  };
  var syncTab = new TabList('.tabBox', '.contents');
  syncTab.fn();
}


function productSec() {
  if ($('.productChildrenPageSec__wrap').length) {
    var sidebar = $("#sidebar");
    var sticked = $("#sticked");
    var stopper = $(".stopper");

    // サイドバーの位置
    var sidebar_top = sidebar.offset().top;
    // 固定するコンテンツの元々の位置
    var sticked_original_top = sticked.offset().top;
    // 固定するコンテンツの高さ
    var sticked_height = sticked.height();

    $(window).on('scroll resize', function(){ // スクロールかリサイズ時
      // 現在の位置
      var scrollTop = $(document).scrollTop();
      // 固定を止めたい位置
      var stopper_pos = stopper.offset().top;

      if ((scrollTop > sticked_original_top) && (scrollTop < stopper_pos - sticked_height)){
        // 現在位置が、初期位置より下で、固定したい範囲より上なら、画面上部にサイドバーを固定
        sticked.css({'position': 'fixed',
        'top': 100,
        'width': sidebar.width()
      });
    } else if(scrollTop >= stopper_pos - sticked_height){
      // 現在位置が固定したい範囲より下のなら、固定したい範囲の最後尾にサイドバーを位置させる
      sticked.css({'position': 'absolute',
      'top': stopper_pos - sticked_height - sidebar_top,
      'width': sidebar.width()
    });
  } else {
    // 現在位置が初期位置より上なら、何もしない
    sticked.css({'position': 'static'});
  }
});
}
}

// function ieScroll() {
//   // 利用ブラウザがIE10か11か
//   if(navigator.userAgent.match(/MSIE 10/i) || navigator.userAgent.match(/Trident\/7\./)) {
//     // マウスホイールでのスクロール時
//     $('body').on("mousewheel", function (){
//       // デフォルトの設定値をOFFに切り替える
//       event.preventDefault();
//       var wd = event.wheelDelta;
//       var csp = window.pageYOffset;
//       window.scrollTo(0, csp - wd);
//     });
//   }
// }

$(function() {
  smoothScroll();
  accordion();
  btnClickFunc();
  headerNav();
  headerNavSp();
  swiperSlider();
  headerModal();
  WOWanimate();
  faqPageTabFunc();
  tabSec();
  productSec();
  // ieScroll();
});
